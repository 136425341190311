<template>
  <div class="">
    <div class="sm:flex sm:flex-col">
      <div class="mt-3 text-center sm:mt-0 sm:text-left">
        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
          {{ action }} {{ newButton.label }}
        </h3>
        <div class="mt-2">
          <label for="email" class="block text-sm font-medium text-gray-700">Name</label>
          <div class="mt-1">
            <input
              type="text"
              v-model="form.role_name"
              class="border px-2 py-1 block w-full sm:text-sm border-gray-300 rounded"
              placeholder="GROUP NAME"
            />
          </div>
        </div>

        <div class="mt-2">
          <textarea
            rows="4"
            v-model="form.role_description"
            class="border block w-full sm:text-sm border-gray-300 rounded-md p-2"
            placeholder="GROUP DESCRIPTION"
          ></textarea>
        </div>
      </div>

      <fieldset class="sm:flex sm:flex-col mt-4">
        <div class="sm:flex sm:justify-between pr-3">
          <legend class="text-lg font-medium text-gray-900">Permissions</legend>
          <input
            type="checkbox"
            name="select-all"
            class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            v-model="checked"
          />
        </div>
        <div
          class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200 sm:max-h-72 sm:overflow-y-scroll pr-3"
        >
          <div
            v-for="(permission, index) in permissions"
            :key="`permission-${index}`"
            class="relative flex items-start py-2"
          >
            <div class="min-w-0 flex-1 text-sm">
              <label for="person-1" class="font-medium text-gray-700 select-none">{{
                permission.name.replace('_', ' ')
              }}</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input
                v-model="form.permissions"
                type="checkbox"
                class="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                :value="permission.id"
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        type="button"
        class="w-full inline-flex justify-center rounded border border-transparent px-3 py-1 bg-indigo-600 font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-xs"
        @click.prevent="update"
      >
        SAVE
      </button>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded border border-gray-300 px-3 py-1 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-xs"
        @click.prevent="setModal({ show: false })"
      >
        CANCEL
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import http from '@/utils/http'

export default {
  name: 'groups-and-permissions',
  data() {
    return {
      form: {
        role_name: '',
        role_description: '',
        permissions: [],
      },
      checked: false,
      permissions: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      http
        .get('/permissions')
        .then(response => {
          this.permissions = _.map(response.data, permission => ({
            id: permission.id,
            name: permission.perm_name,
          }))

          this.form = {
            ...this.form,
            ...this.modal.data,
            permissions: this.modal.data ? _.map(this.modal.data.permissions, 'id') : [],
          }
        })
        .catch(err => {
          console.log('Error ::: ', err)
        })
    },
    update() {
      const action = this.form.id
        ? { method: 'put', url: `/roles/${this.form.id}` }
        : { method: 'post', url: `/roles` }

      http[action.method](action.url, { ...this.form })
        .then(() => {
          this.$bus.$emit(this.modal.notifier)
          this.setModal({ show: false })
        })
        .catch(err => {
          console.log(err)
          this.setModal({ show: false })
        })
    },
  },
  computed: {
    action() {
      return this.modal.payload ? 'Update' : 'Create'
    },
  },
  watch: {
    checked: function (flag) {
      if (flag) this.form.permissions = _.map(this.permissions, 'id')
      else this.form.permissions = []
    },
  },
}
</script>

<style></style>
